import { render, staticRenderFns } from "./customerDialog.vue?vue&type=template&id=0dc24890&scoped=true"
import script from "./customerDialog.vue?vue&type=script&lang=js"
export * from "./customerDialog.vue?vue&type=script&lang=js"
import style0 from "./customerDialog.vue?vue&type=style&index=0&id=0dc24890&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dc24890",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0dc24890')) {
      api.createRecord('0dc24890', component.options)
    } else {
      api.reload('0dc24890', component.options)
    }
    module.hot.accept("./customerDialog.vue?vue&type=template&id=0dc24890&scoped=true", function () {
      api.rerender('0dc24890', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ruge/gsPark/customerService/customerRelaManagement/customerList/customerDialog.vue"
export default component.exports