<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
            <div>
                <p class="cus_title">基础信息</p>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="租户类型" prop="userType">
                            <el-select v-model="ruleForm.userType" filterable placeholder="请选择" style="width: 310px;">
                                <el-option v-for="item in userTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item label="客户编号" prop="userID">
                            <el-input v-model="ruleForm.userID" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="客户企业名称" prop="userCompany">
                            <el-input v-model="ruleForm.userCompany" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="企业类型" prop="enterpriseType">
                            <el-select v-model="ruleForm.enterpriseType" filterable placeholder="请选择"
                                style="width: 310px;">
                                <el-option v-for="item in enterpriseTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="联系人" prop="userName">
                            <el-input v-model="ruleForm.userName" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="userNumber">
                            <el-input v-model="ruleForm.userNumber" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="userMail">
                            <el-input v-model="ruleForm.userMail" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地址" prop="userAddress">
                            <el-input v-model="ruleForm.userAddress" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div>
                <p class="cus_title">招商信息</p>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="客户来源" prop="userSource">
                            <el-select v-model="ruleForm.userSource" filterable placeholder="请选择" style="width: 310px;">
                                <el-option v-for="item in userSourceTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="销售人员" prop="sharePerson">
                            <el-select v-model="ruleForm.sharePerson" value-key="id" filterable placeholder="请选择"
                                @change="getSharePerson" style="width: 310px;">
                                <el-option v-for="item in personTypeOptions" :key="item.value.id" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="电话" prop="sharePersonNumber">
                            <el-input v-model="ruleForm.sharePersonNumber" placeholder="-" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item label="微信号" prop="sharePersonWX">
                            <el-input v-model="ruleForm.sharePersonWX" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="职务" prop="sharePersonWork">
                            <el-input v-model="ruleForm.sharePersonWork" placeholder="-" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div>
                <p class="cus_title">出租信息</p>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="项目" prop="projectType">
                            <el-select v-model="ruleForm.projectType" filterable placeholder="请选择"
                                @change="changeProject" style="width: 310px;">
                                <el-option v-for="item in projectTypeOptions" :key="item.value" :label="item.label"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="楼栋" prop="projectBuild">
                            <el-select v-model="ruleForm.projectBuild" filterable placeholder="请选择"
                                @change="changeBuild" style="width: 310px;">
                                <el-option v-for="item in buildTypeOptions" :key="item.value" :label="item.label"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="房间号" prop="projectRoomNumber">
                            <el-select v-model="ruleForm.projectRoomNumber" value-key="roomId" filterable
                                placeholder="请选择" multiple style="width: 310px;">
                                <el-option v-for="item in roomTypeOptions" :key="item.value.roomId" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <!-- <div>
                <p class="cus_title">出租信息</p>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-cascader :props="propsTree" v-model="selectedItems" @click="handleNodeClick"></el-cascader>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-cascader v-model="selectedOptions" :options="options" :props="props" clearable></el-cascader>
                    </el-col>
                </el-row>
            </div> -->
            <el-form-item align="right" style="margin-top: 60px;">
                <el-button type="plain" @click="clickStep('close')">取消</el-button>
                <el-button type="primary" @click="clickStep('save')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {
    getSalePersonList,
    getStoreList,
    getBuildList,
    getRoomList,
    addCustomers
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
import { label } from "three/examples/jsm/nodes/Nodes.js";
export default {
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
    },
    data() {
        return {
            selectedOptions: [], // 用于绑定选中的值
            props: { multiple: true },
            options: [{
                value: 1,
                label: '东南',
                children: [{
                    value: 2,
                    label: '上海',
                    children: [
                        { value: 3, label: '普陀' },
                        { value: 4, label: '黄埔' },
                        { value: 5, label: '徐汇' }
                    ]
                }, {
                    value: 7,
                    label: '江苏',
                    children: [
                        { value: 8, label: '南京' },
                        { value: 9, label: '苏州' },
                        { value: 10, label: '无锡' }
                    ]
                }, {
                    value: 12,
                    label: '浙江',
                    children: [
                        { value: 13, label: '杭州' },
                        { value: 14, label: '宁波' },
                        { value: 15, label: '嘉兴' }
                    ]
                }]
            }, {
                value: 17,
                label: '西北',
                children: [{
                    value: 18,
                    label: '陕西',
                    children: [
                        { value: 19, label: '西安' },
                        { value: 20, label: '延安' }
                    ]
                }, {
                    value: 21,
                    label: '新疆维吾尔族自治区',
                    children: [
                        { value: 22, label: '乌鲁木齐' },
                        { value: 23, label: '克拉玛依' }
                    ]
                }]
            }],
            treeStoreId: null,
            selectedItems: [],
            propsTree: {
                multiple: true,
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    if (level === 0) {
                        getStoreList().then(res => {
                            const nodes = res.map(item => ({
                                value: item,
                                label: item.storeName,
                                storeId: item.storeId,
                                leaf: 0,
                            }));
                            console.log(nodes, '一级节点');
                            resolve(nodes); // 将resolve(nodes)放在then回调内部
                        }).catch(error => {
                            console.error('Failed to fetch store list: ', error);
                            resolve([]); // 返回空数组或者其他错误处理
                        });
                    }
                    if (level === 1) {
                        getBuildList({
                            storeId: node.data.storeId
                        }).then(res => {
                            const nodes = res.map(item => ({
                                value: item,
                                label: item.buildingName,
                                storeId: item.buildingId,
                                leaf: 0
                            }));
                            console.log(nodes, '二级节点');
                            resolve(nodes); // 将resolve(nodes)放在then回调内部
                        }).catch(error => {
                            console.error('Failed to fetch store list: ', error);
                            resolve([]); // 返回空数组或者其他错误处理
                        });
                    }
                    if (level === 2) {
                        getRoomList({
                            buildingId: node.data.storeId
                        }).then(res => {
                            const nodes = res.map(item => ({
                                value: item,
                                label: item.floorName,
                                buildingId: item.buildingId,
                                leaf: 1
                            }));
                            console.log(nodes, '三级节点');
                            resolve(nodes); // 将resolve(nodes)放在then回调内部
                        }).catch(error => {
                            console.error('Failed to fetch store list: ', error);
                            resolve([]); // 返回空数组或者其他错误处理
                        });
                    }
                    if (level === 3) {
                        resolve([]);
                    }
                    console.log(node, '当前节点');
                }
            },
            ruleForm: {
                userType: '',
                userID: '',
                userCompany: '',
                enterpriseType: '',
                userName: '',
                userNumber: '',
                userMail: '',
                userAddress: '',
                userSource: '',
                sharePerson: '',
                sharePersonNumber: '',
                sharePersonWX: '',
                sharePersonWork: '',
                projectType: '',
                projectBuild: '',
                projectRoomNumber: []


            },
            rules: {
                userType: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                userCompany: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                userName: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                userNumber: [
                    // { required: true, message: '必填项', trigger: 'change' }
                    { required: true, message: '必填项', trigger: 'blur' },
                    { pattern: /^1[0-9]{10}$/, message: '手机号码格式不正确', trigger: 'blur' }
                ],
                userSource: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                sharePerson: [
                    { required: true, message: '必填项', trigger: 'change' }
                ]
            },
            userTypeOptions: [{
                value: 1,
                label: '个人'
            }, {
                value: 2,
                label: '企业'
            }],
            enterpriseTypeOptions: [
                {
                    value: '1',
                    label: 'IT'
                }, {
                    value: '2',
                    label: '金融业'
                }, {
                    value: '3',
                    label: '房地产'
                }, {
                    value: '4',
                    label: '商业服务'
                }, {
                    value: '5',
                    label: '运输/物流'
                }, {
                    value: '6',
                    label: '政府'
                }, {
                    value: '7',
                    label: '文化传媒'
                }, {
                    value: '8',
                    label: '医药'
                }, {
                    value: '9',
                    label: '科技'
                }, {
                    value: '10',
                    label: '其他'
                },
            ],
            userSourceTypeOptions: [
                {
                    value: '1',
                    label: '中介渠道'
                }, {
                    value: '2',
                    label: '全民营销'
                }, {
                    value: '3',
                    label: '自拓'
                }
            ],
            personTypeOptions: [],
            projectTypeOptions: [],
            buildTypeOptions: [],
            roomTypeOptions: []
        }
    },
    created() {
        this.getpersonList()
        this.getProjestList()
    },
    methods: {
        handleNodeClick(data) {
            console.log(data, '点击了');
            // if (data.level === 0) {
            //     this.treeStoreId = data.value; // 点击一级树节点时保存storeId的值
            // }
        },
        clickStep(type) {
            if (type == 'close') {
                this.$emit("close", false);
            } else if (type == 'save') {
                console.log('111');
                console.log(this.currentState, '状态');
                console.log(this.id, 'id');
                if (this.dialogStatus == 'add') {
                    this.$refs["ruleForm"].validate((valid) => {
                        if (valid) {
                            let data = this.ruleForm.projectRoomNumber
                            let customerRoomVOS = []
                            let projectVOS = []
                            if (this.ruleForm.projectType.value == undefined && this.ruleForm.projectBuild.value == undefined) {
                                projectVOS = []
                            } else {
                                projectVOS = [
                                    {
                                        floorName: '',
                                        roomId: '',
                                        roomName: '',
                                        storeId: this.ruleForm.projectType.value,
                                        storeName: this.ruleForm.projectType.label,
                                        buildingId: this.ruleForm.projectBuild.value,
                                        buildingName: this.ruleForm.projectBuild.label,
                                    }
                                ]
                            }
                            customerRoomVOS = data.map(item => {
                                return {
                                    storeId: this.ruleForm.projectType.value,
                                    storeName: this.ruleForm.projectType.label,
                                    buildingId: this.ruleForm.projectBuild.value,
                                    buildingName: this.ruleForm.projectBuild.label,
                                    floorName: item.floorName,
                                    roomId: item.roomId,
                                    roomName: item.roomName,
                                };
                            });
                            console.log(projectVOS, '项目');
                            console.log(customerRoomVOS, '房间VO');
                            let list = {
                                tenantType: this.ruleForm.userType == 1 ? '个人' : '企业',
                                enterpriseName: this.ruleForm.userCompany,
                                enterpriseType: this.ruleForm.enterpriseType,
                                contacts: this.ruleForm.userName,
                                contactNumber: this.ruleForm.userNumber,
                                email: this.ruleForm.userMail,
                                address: this.ruleForm.userAddress,
                                source: this.ruleForm.userSource,
                                employeeName: this.ruleForm.sharePerson.employeeName,
                                employeePhone: this.ruleForm.sharePerson.userPhone,
                                employeeRole: this.ruleForm.sharePerson.roleName,
                                customerRoomVOS: customerRoomVOS.length == 0 ? projectVOS : customerRoomVOS
                            }
                            console.log(list, '表单');
                            addCustomers(list).then(res => {
                                console.log(res);
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '新增操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        }
                    })
                }
            }
        },
        getpersonList() {
            getSalePersonList().then(res => {
                res.data.map((item) => {
                    this.personTypeOptions.push({
                        value: item,
                        label: item.employeeName,
                    })
                })
            })
        },
        getSharePerson() {
            console.log(this.ruleForm.sharePerson);
            this.ruleForm.sharePersonNumber = this.ruleForm.sharePerson.userPhone
            this.ruleForm.sharePersonWork = this.ruleForm.sharePerson.roleName
        },
        getProjestList() {
            getStoreList().then(res => {
                if (res) {
                    res.map((item) => {
                        this.projectTypeOptions.push({
                            value: item.storeId,
                            label: item.storeName,
                        })
                    })
                }
            })
        },
        changeProject() {
            this.buildTypeOptions = []
            this.ruleForm.projectBuild = {}
            this.roomTypeOptions = []
            this.ruleForm.projectRoomNumber = []
            this.getBulidData()
            console.log('选中项目', this.ruleForm.projectType.value);
        },
        getBulidData() {
            getBuildList({
                storeId: this.ruleForm.projectType.value
            }).then(res => {
                console.log(res);
                res.map((item) => {
                    this.buildTypeOptions.push({
                        value: item.buildingId,
                        label: item.buildingName,
                    })
                })
            })
        },
        changeBuild() {
            this.roomTypeOptions = []
            this.ruleForm.projectRoomNumber = []
            this.getRoomData()
            console.log('选中楼栋', this.ruleForm.projectBuild);
        },
        getRoomData() {
            getRoomList({
                buildingId: this.ruleForm.projectBuild.value
            }).then(res => {
                console.log(res);
                res.map((item) => {
                    this.roomTypeOptions.push({
                        value: item,
                        label: item.roomName,
                    })
                })
            })
        },
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
                // this.getMeetingDetail(id);
            },
            immediate: true,
        },
        dialogStatus: {
            handler(val) {
                this.currentState = val;
            },
            immediate: true,
        },
    }

}
</script>
<style lang="less" scoped>
.cus_title {
    margin-top: 16px;
    margin-bottom: 14px;
    color: #909399;
    font-size: 12px;
    font-weight: bold;
    font-family: Noto Sans SC, Noto Sans SC;
}
</style>