var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "div",
            [
              _c("p", { staticClass: "cus_title" }, [_vm._v("基础信息")]),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "租户类型", prop: "userType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.userType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "userType", $$v)
                                },
                                expression: "ruleForm.userType",
                              },
                            },
                            _vm._l(_vm.userTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "客户企业名称", prop: "userCompany" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.userCompany,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "userCompany", $$v)
                              },
                              expression: "ruleForm.userCompany",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "企业类型", prop: "enterpriseType" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.enterpriseType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "enterpriseType", $$v)
                                },
                                expression: "ruleForm.enterpriseType",
                              },
                            },
                            _vm._l(_vm.enterpriseTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "userName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "userName", $$v)
                              },
                              expression: "ruleForm.userName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "userNumber" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.userNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "userNumber", $$v)
                              },
                              expression: "ruleForm.userNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮箱", prop: "userMail" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.userMail,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "userMail", $$v)
                              },
                              expression: "ruleForm.userMail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "userAddress" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.userAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "userAddress", $$v)
                              },
                              expression: "ruleForm.userAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", { staticClass: "cus_title" }, [_vm._v("招商信息")]),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户来源", prop: "userSource" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.userSource,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "userSource", $$v)
                                },
                                expression: "ruleForm.userSource",
                              },
                            },
                            _vm._l(_vm.userSourceTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售人员", prop: "sharePerson" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: {
                                "value-key": "id",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              on: { change: _vm.getSharePerson },
                              model: {
                                value: _vm.ruleForm.sharePerson,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sharePerson", $$v)
                                },
                                expression: "ruleForm.sharePerson",
                              },
                            },
                            _vm._l(_vm.personTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value.id,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话", prop: "sharePersonNumber" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "-", disabled: "" },
                            model: {
                              value: _vm.ruleForm.sharePersonNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "sharePersonNumber", $$v)
                              },
                              expression: "ruleForm.sharePersonNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "职务", prop: "sharePersonWork" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "-", disabled: "" },
                            model: {
                              value: _vm.ruleForm.sharePersonWork,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "sharePersonWork", $$v)
                              },
                              expression: "ruleForm.sharePersonWork",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", { staticClass: "cus_title" }, [_vm._v("出租信息")]),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目", prop: "projectType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProject },
                              model: {
                                value: _vm.ruleForm.projectType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "projectType", $$v)
                                },
                                expression: "ruleForm.projectType",
                              },
                            },
                            _vm._l(_vm.projectTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "楼栋", prop: "projectBuild" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeBuild },
                              model: {
                                value: _vm.ruleForm.projectBuild,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "projectBuild", $$v)
                                },
                                expression: "ruleForm.projectBuild",
                              },
                            },
                            _vm._l(_vm.buildTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "房间号", prop: "projectRoomNumber" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: {
                                "value-key": "roomId",
                                filterable: "",
                                placeholder: "请选择",
                                multiple: "",
                              },
                              model: {
                                value: _vm.ruleForm.projectRoomNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "projectRoomNumber",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.projectRoomNumber",
                              },
                            },
                            _vm._l(_vm.roomTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value.roomId,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "60px" },
              attrs: { align: "right" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "plain" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("close")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("save")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }